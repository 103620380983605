import React, {
  useState,
  forwardRef,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'
import axios from 'axios'
import { Grid, Button } from '@material-ui/core'

import EnableResults from 'views/dialog/EnableResultsApplicant'
import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})
const useStyles1 = makeStyles(cardBodyStyle)

export default function TestsDone(props) {
  const classes = useStyles()
  const classes1 = useStyles1()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [returnLogin, setReturnLogin] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [idRow, setIdRow] = useState('')

  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const columns = [
    {
      title: 'Prueba',
      field: 'test.name',
      width: '45%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 11,
      },
    },
    {
      title: 'Cargo',
      field: 'test.testjob[0].name',
      width: '45%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 10,
      },
    },
    {
      title: 'Fecha finalizada',
      render: (rowData) => format(new Date(rowData.endDate), 'dd-MM-yyyy'),
      width: '10%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 10,
        textAlign: 'center',
      },
    },
    {
      title: '---',
      render: (rowData) =>
        rowData.test.showApplicantreport && (
          <Button
            className={classes1.buttonSubmit6}
            onClick={() => openResult(rowData)}
          >
            Reporte
          </Button>
        ),
      width: '5%',
      cellStyle: {
        textAlign: 'CENTER',
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
      sorting: false,
    },
  ]

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${urlServices}tests-users/user/${localStorage.getItem('id')}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )

      if (response.status === 200) {
        const result = response.data.filter(
          (filtered) =>
            filtered.status === 3 &&
            parseInt(localStorage.getItem('codInstitucion')) ===
              parseInt(filtered.test.user.accountId)
        )
        setRows(result)
      }
    } catch (e) {
      if (e.response.status === 404) {
        setRows([])
      } else if (e.response.status === 401) {
        localStorage.clear()
        setReturnLogin(true)
      } else {
        setError(true)
        console.log(e)
      }
    } finally {
      setLoader(false)
    }
  }, [keyAplication, urlServices, keyAuthorization])

  useEffect(() => {
    if (props.refreshTestDone) {
      refreshTable()
    } else {
      fetchData()
    }
  }, [fetchData, props.refreshTestDone])

  const refreshTable = useCallback(() => {
    fetchData()
    props.refresh()
  }, [fetchData, props])

  const handleCloseResults = () => {
    setOpenResults(false)
  }

  const openResult = (data) => {
    const out = {
      testId: data.test.id,
      testName: data.test.name,
      testTime: data.test.time,
      testScore: data.test.totalScore,
      userId: data.user.id,
      userNames: `${data.user.name} ${data.user.lastName}`,
      userEmail: data.user.email,
      userDocument: data.user.documentId,
    }

    setIdRow(out)
    setOpenResults(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Alert variant="filled" severity="success">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS FINALIZADAS</b>
            </Alert>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert severity="error">
                Error en la consulta con el servidor.
              </Alert>
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{
                        width: 40,
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                columns={columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={rows}
                localization={{
                  toolbar: {
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Última página',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay pruebas realizadas.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                icons={tableIcons}
                options={{
                  search: false,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 20,
                  paging: false,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 12,
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                  toolbar: false,
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes.tableRow} {...props} />
                  ),
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openResults && (
        <EnableResults
          open={openResults}
          exit={handleCloseResults}
          id={idRow}
        />
      )}
    </GridContainer>
  )
}
