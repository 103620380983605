import React, { useEffect, useContext, useState } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { makeStyles } from '@material-ui/core/styles'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ArrowRightAlt } from '@material-ui/icons'
import font from 'assets/font/Roboto-Bold.ttf'
import axios from 'axios'

import loadingImg from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const styles = StyleSheet.create({
  viewer: {
    width: '98%', //the pdf viewer will take up all of the width and height
    height: 497,
  },
  body: {
    padding: 25,
  },
  table: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    marginTop: 5,
    borderCollapse: 'collapse',
  },
  tableHeader: {
    display: 'table',
    width: '100%',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderCollapse: 'collapse',
  },
  tableDinamic: {
    display: 'table',
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    marginTop: 25,
    borderCollapse: 'collapse',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    width: '100%',
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol2Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  colHeaderName: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    marginTop: '-5px',
    fontSize: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 322,
  },
  colHeader: {
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
    marginTop: '-5px',
    fontSize: 8,
  },
  tableCol1HeaderDinamic: {
    width: '100%',
    borderWidth: 0,
    padding: 8,
    textAlign: 'center',
    backgroundColor: 'rgb(242, 242, 242)',
  },
  tableCol1: {
    borderLeftColor: 'rgba(255, 255, 255, 1)',
    borderTopColor: 'rgba(255, 255, 255, 1)',
    borderRightColor: 'rgba(255, 255, 255, 1)',
    borderBottomColor: 'rgba(255, 255, 255, 1)',
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'Roboto-Bold',
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Roboto-Bold',
  },
  tableCell1: {
    fontSize: 8,
  },
  tableCellHeaderDinamic2: {
    fontSize: 10,
    width: 300,
    padding: '5px 0 0 5px',
  },

  tableCellHeader1: {
    fontSize: 8,
    width: 60,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader2: {
    fontSize: 7,
    width: 320,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader3: {
    fontSize: 8,
    width: 450,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    padding: '5px 0 0 80px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
  },
  tableCellHeader4: {
    fontSize: 7,
    width: 150,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
    textTransform: 'uppercase',
  },
  tableCellHeader44: {
    fontSize: 7,
    width: 80,
    borderLeftColor: 'rgba(224, 224, 224, 1)',
    borderTopColor: 'rgba(224, 224, 224, 1)',
    borderRightColor: 'rgba(224, 224, 224, 1)',
    borderBottomColor: 'rgba(224, 224, 224, 1)',
    borderWidth: 1,
    textAlign: 'center',
    padding: '5px 4px 4px -15px',
    backgroundColor: 'rgb(242, 242, 242)',
    fontFamily: 'Roboto-Bold',
    marginTop: '-5px',
    textTransform: 'uppercase',
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 500,
  },
  tableCol1HeaderLeftANDRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCellData1: {
    padding: 4,
    width: 60,
  },
  tableCellData2: {
    padding: '5px 4px 4px 4px',
    width: 322,
    textTransform: 'uppercase',
    fontSize: 6.8,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  tableCellData3: {
    padding: 4,
    width: 450,
  },
  tableCellData4: {
    padding: '5px 5px 4px 5px',
    width: 150,
  },
  tableCellData44: {
    padding: '5px 4px 4px 4px',
    width: 80,
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: '#000',
    padding: 1,
    borderRadius: 10,
    width: 100,
  },

  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: 'Roboto-Bold',
  },
})

Font.register({
  family: 'Roboto-Bold',
  src: font,
})
// Create Document Component
function PrintPDFReportGeneralJobs({ data, exit, open }) {
  const classes = useStyles()
  const { jobTpm, jobFactor, rowsJobUser } = data

  const [account, setAccount] = useState('')
  const [loader, setLoader] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const handleClose = () => {
    exit()
  }

  // UseEffect para manejar cambios en `open`
  useEffect(() => {
    if (open) {
      accountSearch() // Solo se llama cuando open es true
    }
    // Solo ejecuta la función cuando open cambia a true
  }, [open])

  const accountSearch = () => {
    setLoader(true)
    axios
      .get(`${urlServices}accounts/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAccount(response.data.companyName)
          setTimeout(() => {
            setLoader(false)
          }, 1000)
        }
      })
      .catch((e) => {})
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="body"
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <DialogContent dividers>
        {loader ? (
          <center>
            <div style={{ margin: 70 }}>
              <img
                src={loadingImg}
                alt="loadingImg"
                style={{
                  width: '60px',
                }}
              />
              <br></br>
              <b style={{ fontSize: 16, marginBottom: 20 }}>Cargando PDF...</b>
            </div>
          </center>
        ) : (
          <PDFViewer style={styles.viewer}>
            <Document>
              <Page style={styles.body} size="A4" orientation="landscape" wrap>
                <View style={{ marginBottom: 10 }}>
                  <Text style={styles.titlePage}>
                    {' '}
                    Reporte general por cargo
                  </Text>
                  <Text style={styles.lineBottom}></Text>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1HeaderLeft}>
                      <Text style={styles.tableCellHeader}>
                        Institución / Empresa:
                        <Text style={styles.tableCell}> {' ' + account}</Text>
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1HeaderLeft}>
                      <Text style={styles.tableCellHeader}>
                        Cargo:
                        <Text style={styles.tableCell}>
                          {' ' + jobTpm.name}
                        </Text>
                      </Text>
                    </View>
                  </View>
                  {/*params.startDate && params.endDate ? (
              <Fragment>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1HeaderLeftANDRight}>
                    <Text style={styles.tableCellHeader}>
                      Desde:
                      <Text style={styles.tableCell}>
                        {' ' + params.startDate}
                      </Text>
                    </Text>
                  </View>
                  <View style={{ width: 120 }}>
                    <Text style={styles.tableCellHeader}>
                      Hasta:
                      <Text style={styles.tableCell}>
                        {' ' + params.endDate}
                      </Text>
                    </Text>
                  </View>
                </View>
              </Fragment>
            ) : (
              <></>
            )*/}
                </View>

                <View style={styles.tableDinamic}>
                  <View style={styles.tableRowDinamic}>
                    <View style={styles.tableCol1HeaderDinamic}>
                      <Text style={styles.tableCellHeaderDinamic}>
                        LISTADO DE CANDIDATOS
                      </Text>
                    </View>
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCellHeader2}>
                        <Text>CANDIDATO</Text>
                      </View>
                      <View style={styles.tableCellHeader4}>
                        <Text>DOCUMENTO</Text>
                      </View>

                      {jobFactor.map((rowFactor) => {
                        return (
                          <View style={styles.tableCellHeader4}>
                            <Text> {rowFactor.name}</Text>
                          </View>
                        )
                      })}
                      <View style={styles.tableCellHeader44}>
                        <Text>TOTAL</Text>
                      </View>
                      <View style={styles.tableCellHeader4}>
                        <Text>EQUIVALENCIA</Text>
                      </View>
                    </View>
                  </View>

                  {rowsJobUser.map((row1, i) => {
                    let scoretotal = 0
                    return (
                      <View key={i}>
                        <View style={styles.table}>
                          <View style={styles.tableRow}>
                            <View style={styles.colHeaderName} break>
                              <Text style={styles.tableCellData2}>
                                {`${row1.applicant.lastName} ${row1.applicant.name}`}
                              </Text>
                            </View>
                            <View style={styles.colHeader} break>
                              <Text style={styles.tableCellData4}>
                                {row1.applicant.documentId}
                              </Text>
                            </View>

                            {row1.testsResultsTpm.length > 0 ? (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                                break
                              >
                                {jobFactor.map((row2) => {
                                  scoretotal += parseFloat(
                                    row1.testsResultsTpm.find(
                                      (e) => e.factorId == row2.factorId
                                    )?.score || 0
                                  )
                                  return (
                                    <View style={styles.colHeader}>
                                      <Text style={styles.tableCellData4}>
                                        {parseFloat(
                                          row1.testsResultsTpm.find(
                                            (e) => e.factorId == row2.factorId
                                          )?.score || 0
                                        ).toFixed(2)}
                                      </Text>
                                    </View>
                                  )
                                })}
                              </View>
                            ) : (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                                break
                              >
                                {jobFactor.map(() => {
                                  return (
                                    <View style={styles.colHeader}>
                                      <Text style={styles.tableCellData4}>
                                        {parseFloat(0).toFixed(2)}
                                      </Text>
                                    </View>
                                  )
                                })}
                              </View>
                            )}
                            <View style={styles.colHeader}>
                              <Text style={styles.tableCellData44}>
                                {parseFloat(scoretotal).toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.colHeader}>
                              <Text style={styles.tableCellData4}>
                                {parseFloat(scoretotal).toFixed(2)} /{' '}
                                {parseFloat(jobTpm.totalScore)}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    )
                  })}
                </View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Alert severity="info">
              {' '}
              <b style={{ fontSize: 14 }}>
                Puede salir y volver a generar el reporte sin esperar
                nuevamente.
              </b>
            </Alert>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={handleClose}
              style={{ float: 'right', marginLeft: 10 }}
            >
              Regresar <ArrowRightAlt style={{ margin: '0 30px 0 10px' }} />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
export default PrintPDFReportGeneralJobs
