import React, { Fragment, useEffect, useState, useContext } from 'react'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { Alert, Autocomplete, Skeleton } from '@material-ui/lab'
import { Box, Grid, TextField } from '@material-ui/core'
import axios from 'axios'

export default function LoadJobsAccount(props) {
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)
  const [isLoadedd, setIsLoadedd] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const accountId = localStorage.getItem('account_id')
        const response = await axios.get(
          `${urlServices}jobs-tpm/account/${accountId}`,
          {
            headers: {
              application: keyAplication,
              Authorization: `Bearer ${keyAuthorization}`,
            },
          }
        )

        const filteredResults = response.data.data.filter(
          (job) =>
            (job.status === 1 || job.status === 2) &&
            (props.all || job.managePasswords === true) &&
            (!props.takePhoto || job.takePhoto === true)
        )

        setResults(filteredResults)
      } catch (error) {
        handleApiError(error)
      } finally {
        setIsLoadedd(true)
      }
    }

    const handleApiError = (error) => {
      if (error.response?.status === 401) {
        setTimeout(() => {
          localStorage.clear()
          setReturnLogin(true)
        }, 200)
        setError(true)
      } else {
        setError(false)
      }
    }

    fetchJobs()
  }, [urlServices, keyAplication, props.all, props.takePhoto])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      {error && (
        <Alert severity="error">
          Error en la consulta de <b>CARGO DE LA CUENTA</b>
        </Alert>
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: '100%' }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue)
          }}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cargo"
              variant="standard"
              error={props.error}
              required
            />
          )}
        />
      )}
    </Fragment>
  )
}
