import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

import { makeStyles, withStyles } from '@material-ui/core/styles'

// core components
import { Redirect } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { AddAPhotoOutlined, ExpandMore } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Backdrop,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Box,
  TableHead,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

import LoadJobsAccount from 'components/services/LoadJobsAccount'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'rgb(237, 247, 237)',
    color: '#000',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles(cardBodyStyle)

export default function ReportPhotosPsychometricUsers(props) {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [errorSystem, setErrorSystem] = useState(false)
  const [errorMsjSystem, setErrorMsjSystem] = useState('')
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication, urlWeb } = useContext(UrlServicesContext)

  const submitAdd = async (job) => {
    setErrorSystem(false)
    setoOpenBackdrop(true)
    if (job) {
      try {
        const response = await axios.get(
          `${urlServices}reports/job-photo-user/${job.id}`,
          {
            headers: {
              application: keyAplication,
              Authorization: `Bearer ${keyAuthorization}`,
            },
          }
        )

        if (response.status === 200) {
          setRows(response.data)
          setoOpenBackdrop(false)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.error(error)
          setErrorSystem(true)
          setErrorMsjSystem(
            'Ocurrió un error interno durante la consulta. Ponerse en contacto con el equipo de sistemas para resolverlo.'
          )
        }
        setoOpenBackdrop(false) // Asegúrate de cerrar el backdrop en caso de error
      }
    } else {
      setRows([])
      setoOpenBackdrop(false)
    }
  }

  const changeJobsAccount = (pro) => {
    submitAdd(pro)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>
                <AddAPhotoOutlined className={classes.iconWhite} /> Reporte de
                fotos por cargo
              </div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  {errorSystem && (
                    <Alert severity="error">{errorMsjSystem}</Alert>
                  )}
                </GridItem>
                <GridItem xs={12} md={12}>
                  <Alert severity="info">
                    Fotos de candidatos por cargo (tomadas durante la prueba).
                  </Alert>
                </GridItem>
                <GridItem xs={12} md={8}>
                  <LoadJobsAccount
                    refresh={changeJobsAccount}
                    all={true}
                    takePhoto={true}
                  />
                </GridItem>
              </GridContainer>
              {rows.length > 0 ? (
                <Grid container spacing={2} style={{ marginTop: 30 }}>
                  <Grid item xs={12} md={12}>
                    <b style={{ fontSize: 16 }}>
                      Listado de candidatos para el cargo{' '}
                      <span
                        style={{ textTransform: 'uppercase', color: 'green' }}
                      >
                        {rows[0].jobtesttpm.job.name}
                      </span>
                    </b>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                    {rows.map((testGroup, index) => (
                      <Accordion
                        key={`test-${testGroup.jobTestId}-${index}`}
                        elevation={3}
                        expanded
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls={`panel-${testGroup.jobTestId}-content`}
                          id={`panel-${testGroup.jobTestId}-header`}
                        >
                          <b style={{ fontSize: 18 }}>
                            {testGroup.jobtesttpm.test.name}
                          </b>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2} style={{ padding: 5 }}>
                            <Grid item xs={12} sm={12}>
                              <TableContainer>
                                <Table aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell align="left">
                                        FOTOS
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        CANDIDATO
                                      </StyledTableCell>
                                      <StyledTableCell align="left">
                                        DOCUMENTO
                                      </StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {testGroup.applicants.map(
                                      (applicant, applicantIndex) => (
                                        <React.Fragment
                                          key={`applicant-${applicant.id}-${applicantIndex}`}
                                        >
                                          <StyledTableRow>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <div style={{ display: 'flex' }}>
                                                {/* Mostrar hasta 3 fotos (reales o placeholders) */}
                                                {Array.from({ length: 3 }).map(
                                                  (_, index) => {
                                                    const photo =
                                                      applicant.test.photos?.[
                                                        index
                                                      ]

                                                    return (
                                                      <Avatar
                                                        key={`photo-${applicant.id}-${index}`}
                                                        alt={
                                                          photo
                                                            ? `photo-${index}`
                                                            : 'no-image'
                                                        }
                                                        variant="rounded"
                                                        src={
                                                          photo
                                                            ? `${urlWeb}files/jobs_users_photos/${photo.picture}`
                                                            : undefined
                                                        }
                                                        style={{
                                                          margin: '0px 3px',
                                                          width: 40,
                                                          height: 40,
                                                          backgroundColor: photo
                                                            ? 'transparent'
                                                            : '#e0e0e0', // Fondo gris para placeholders
                                                        }}
                                                      >
                                                        {!photo && (
                                                          <Avatar
                                                            key={`photo-${applicant.id}-${index}`}
                                                            variant="rounded"
                                                            style={{
                                                              margin: '0px 3px',
                                                              width: 40,
                                                              height: 40,
                                                              backgroundColor:
                                                                '#ccc',
                                                            }}
                                                          />
                                                        )}
                                                      </Avatar>
                                                    )
                                                  }
                                                )}
                                              </div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {`${applicant.name} ${applicant.lastName}`}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {`${applicant.documentId}`}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        </React.Fragment>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="200px"
                  color="#C5C5C5"
                  fontWeight="bold"
                >
                  <Typography variant="h6">No hay resultados</Typography>
                </Box>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <CircularProgress color="inherit" />
            </center>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ fontSize: 14, color: 'white', fontWeight: 'bold' }}
          >
            <center>
              <span style={{ fontSize: 16 }}>No cierre la ventana</span>
              <br></br>(Esto puede tardar unos minutos dependiendo el número de
              postulantes)
            </center>
          </GridItem>
        </GridContainer>
      </Backdrop>
    </Fragment>
  )
}
