import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Cancel, EventOutlined } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import axios from 'axios'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const MuiDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent)

const MuiDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions)

export default function ChangeStatusJobsPsychometrics(props) {
  const classes = useStyles()
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [severity, setSeverity] = useState('success')
  const [returnLogin, setReturnLogin] = React.useState(false)

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  let keyAuthorization = localStorage.getItem('Session')

  const accountId = localStorage.getItem('account_id')

  let data = props.id

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const handleEnable = () => {
    setOpenAlert(false)
    const dataValue = {
      status: data.status,
    }

    axios
      .put(`${urlServices}jobs-tpm/${data.data.id}`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response2) => {
        setMensaje('Registro actualizado con éxito.')
        setSeverity('success')
        setOpenAlert(true)
        props.callBackRefresh()
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setMensaje('Error al registrar!!')
          setSeverity('error')
          setOpenAlert(true)
        }
      })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <MuiDialogContent dividers>
          <Grid container>
            <Grid item xs={12} md={12} style={{ paddingTop: 30 }}>
              <center>
                <EventOutlined style={{ fontSize: 50, color: 'ref' }} />
                <br></br>
                <div className={classes.titleCardFilter}>
                  ¿Desea {data.status === 1 ? 'publicar' : 'finalizar'} esta
                  prueba?
                </div>
              </center>
            </Grid>
            <Grid item xs={12} sm={12} style={{ fontSize: 16 }}>
              {data.status === 1 && (
                <Alert severity="warning">
                  Por favor, comparta con el candidato el código de institución{' '}
                  <b style={{ fontSize: 18 }}> {accountId}</b> que necesitará
                  para acceder al sistema con su clave y aplicar al cargo.
                </Alert>
              )}
              <br></br>
            </Grid>
            <Grid item xs={12} md={12}>
              {openAlert && (
                <Alert severity={severity === 'error' ? 'error' : 'success'}>
                  {mensaje}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              {!openAlert && (
                <center>
                  <Button
                    className={classes.buttonSubmit2}
                    onClick={handleEnable}
                  >
                    {data.status === 1 ? 'Publicar' : 'Finalizar'}
                  </Button>
                </center>
              )}
            </Grid>
          </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </MuiDialogActions>
      </Dialog>
    </Fragment>
  )
}
