import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Search,
  Cancel,
  Layers,
  AddCircle,
  Assignment,
  PersonAdd,
  HowToReg,
  Edit,
  ScheduleOutlined,
  SendOutlined,
} from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import { Grid, LinearProgress, Button, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import format from 'date-fns/format'

import NewJobPsychometrics from 'views/dialog/NewJobPsychometrics'
import EditJobPsychometrics from 'views/dialog/EditJobPsychometrics'
import ChangeStatusJobsPsychometrics from 'views/dialog/ChangeStatusJobsPsychometrics'
import DisablePsychometrics from 'views/dialog/DisablePsychometrics'
import SelectTestPsychometrics from 'views/dialog/SelectTestPsychometrics'
import EnableTestJobsPsychometrics from 'views/dialog/EnableTestJobsPsychometrics'
import EnableApplicantJobsPsychometrics from 'views/dialog/EnableApplicantJobsPsychometrics'
import ViewApplicantTestPsychometrics from 'views/dialog/ViewApplicantTestPsychometrics'

import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: 4,
      },
    },
  },
})

export default function JobsPsychometrics() {
  let url = ''
  const classes = useStyles()
  const tableRef = React.createRef()
  const classes2 = useStyles2()

  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openDesHabilitar, setOpenDesHabilitar] = useState(false)
  const [openTestPsychometrics, setOpenTestPsychometrics] = useState(false)
  const [openApplicants, setOpenApplicants] = useState(false)
  const [openTests, setOpenTests] = useState(false)
  const [tests, setTests] = useState(false)
  const [openApplicantsTests, setOpenApplicantsTests] = useState(false)
  const [activatePhoto, setActivatePhoto] = useState(false)
  const [activateApplicantreport, setActivateApplicantreport] = useState(false)
  const [openLoader, setOpenLoader] = useState(true)

  const accountId = localStorage.getItem('account_id')

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Cargo',
        field: 'name',
        render: (rowData) => {
          return <span>{rowData.name}</span>
        },
        width: '80%',
        cellStyle: {
          //whiteSpace: 'nowrap',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Puntaje',
        field: 'totalScore',
        width: '2%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Equivalencia',
        field: 'equivalent',
        width: '2%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Contraseñas',
        field: 'managePasswords',
        width: '2%',
        render: (rowData) => {
          const text = rowData.managePasswords ? 'SI' : 'NO'
          return <b>{text}</b>
        },
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Creado',
        width: '15%',
        render: (rowData) => format(new Date(rowData.createdAt), 'dd-MM-yyyy'),
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'FINALIZAR'
              : rowData.status === 99
              ? 'PUBLICAR'
              : 'FINALIZADO'
          const color =
            rowData.status === 1
              ? '#024242'
              : rowData.status === 99
              ? 'orange'
              : ''
          return rowData.status === 2 ? (
            <Alert
              icon={false}
              severity="success"
              style={{ fontSize: 10, fontWeight: 'bold' }}
            >
              FINALIZADO
            </Alert>
          ) : (
            <>
              <Button
                variant="contained"
                style={{
                  fontSize: 10,
                  background: color,
                  color: '#fff',
                  fontWeight: 'bold',
                  padding: '4px 10px',
                }}
                endIcon={
                  rowData.status === 99 ? (
                    <SendOutlined />
                  ) : (
                    <ScheduleOutlined />
                  )
                }
                onClick={() =>
                  publishSend(rowData, rowData.status === 99 ? 1 : 2)
                }
              >
                {text}
              </Button>
              <br></br>
              <br></br>
              <Box
                style={{ display: rowData.status === 99 ? 'none' : 'block' }}
              >
                <b>En proceso</b>
                <LinearProgress />
              </Box>
            </>
          )
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
      },
    ],
  })

  useEffect(() => {
    const init = async () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      await fetchAccountData()
    }

    init()
  }, [])

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const fetchAccountData = async () => {
    try {
      if (!accountId) throw new Error('No account ID found')

      const response = await fetch(`${urlServices}accounts/${accountId}`, {
        method: 'GET',
        headers: {
          application: keyAplication,
          Authorization: `Bearer ${keyAuthorization}`,
        },
      })

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`)

      const result = await response.json()

      if (result) {
        setActivatePhoto(result.activatePhoto ?? false)
        setActivateApplicantreport(result.activateApplicantreport ?? false)
      }
    } catch (error) {
      console.error('Error fetching account data:', error)
      // Aquí podrías agregar manejo de errores visual (ej: mostrar un toast)
    } finally {
      setOpenLoader(false)
    }
  }

  const handleCloseNew = () => {
    setOpenNew(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const handleCloseChangeStatus = () => {
    setOpenChangeStatus(false)
  }

  const handleCloseDesHabilitar = () => {
    setOpenDesHabilitar(false)
  }

  const handleCloseTestPsychometrics = () => {
    setOpenTestPsychometrics(false)
    RefreshTable()
  }

  const handleCloseTests = () => {
    setOpenTests(false)
  }

  const handleCloseApplicants = () => {
    setOpenApplicants(false)
  }

  const handleCloseApplicantsTests = () => {
    setOpenApplicantsTests(false)
  }

  const testsSend = (data) => {
    setTests(data)
    setOpenApplicants(true)
  }

  const publishSend = (data, status) => {
    setOpenChangeStatus(true)
    setIdRow({ data: data, status: status })
  }

  const handleOpenJob = (event, option, rowData) => {
    if (option === 1) setOpenNew(true)
    else {
      setIdRow(rowData)
      setOpenEdit(true)
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Gestión de cargos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={6} sm={6} style={{ fontSize: 16 }}>
                <b>Código institución: </b>{' '}
                <span
                  style={{
                    fontSize: 18,
                    padding: 10,
                    width: 50,
                    background: 'rgb(255, 244, 229)',
                    borderRadius: 50,
                    color: 'rgb(102, 60, 0)',
                    fontWeight: 'bold',
                  }}
                >
                  {accountId}
                </span>
              </Grid>
              <Grid item xs={6} sm={6}>
                {openLoader ? (
                  <img
                    alt="loader"
                    src={img}
                    style={{
                      width: 40,
                      marginTop: 20,
                      float: 'right',
                      margin: 15,
                    }}
                  />
                ) : (
                  <Button
                    className={classes.buttonRight}
                    onClick={(e) => handleOpenJob(e, 1)}
                    startIcon={<AddCircle />}
                  >
                    Nuevo cargo
                  </Button>
                )}
              </Grid>
            </Grid>
            <ThemeProvider theme={theme}>
              <MaterialTable
                title=""
                columns={state.columns}
                tableRef={tableRef}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    if (query.search === '') {
                      url = `${urlServices}jobs-tpm/account/${accountId}?limit=${
                        query.pageSize
                      }&page=${query.page + 1}`
                    } else {
                      url = `${urlServices}jobs-tpm/account/${accountId}`
                    }
                    fetch(url, {
                      method: 'get',
                      headers: {
                        application: keyAplication,
                        Authorization: 'Bearer ' + keyAuthorization,
                      },
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          //data: result.data,
                          data:
                            result.data !== undefined
                              ? result.data.filter(function (obj) {
                                  return Object.keys(obj).some(function (key) {
                                    return obj[key]
                                      ? obj[key]
                                          .toString()
                                          .toLowerCase()
                                          .includes(query.search)
                                      : false
                                  })
                                })
                              : [],
                          page: result.page !== undefined ? result.page - 1 : 0,
                          totalCount:
                            result.total !== undefined ? result.total : 0,
                        })
                      })
                  })
                }
                localization={{
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={[
                  (rowData1) => ({
                    icon: () => <HowToReg />,
                    tooltip: 'Candidatos',
                    onClick: (event, rowData) => {
                      setOpenApplicantsTests(true)
                      setIdRow(rowData)
                    },
                    hidden:
                      rowData1.status === 1 || rowData1.status === 2
                        ? false
                        : true,
                  }),
                  (rowData1) => ({
                    icon: () => <PersonAdd />,
                    tooltip: 'Agregar candidatos',
                    onClick: (event, rowData) => {
                      setOpenApplicants(true)
                      setIdRow(rowData)
                    },
                    hidden: rowData1.status === 1 ? false : true,
                  }),
                  (rowData2) => ({
                    icon: () => <Assignment />,
                    tooltip: 'Pruebas',
                    onClick: (event, rowData) => {
                      setOpenTestPsychometrics(true)
                      setIdRow(rowData)
                    },
                  }),
                  (rowData1) => ({
                    icon: () => <Edit />,
                    tooltip: 'Editar',
                    onClick: (event, rowData) =>
                      handleOpenJob(event, 2, rowData),
                    hidden: openLoader
                      ? true
                      : rowData1.status === 1
                      ? true
                      : false,
                  }),
                  (rowData1) => ({
                    icon: () => <Cancel />,
                    tooltip: 'Eliminar',
                    onClick: (event, rowData) => {
                      setOpenDesHabilitar(true)
                      setIdRow(rowData)
                    },
                    hidden: rowData1.status === 1 ? false : true,
                  }),
                  /*(rowData2) => ({
                    icon: () => (
                      <AssignmentTurnedIn style={{ color: 'green' }} />
                    ),
                    tooltip: 'Publicar',
                    onClick: (event, rowData) => {
                      setOpenChangeStatus(true)
                      setIdRow(rowData)
                    },
                    hidden:
                      rowData2.status === 0 || rowData2.status === 99
                        ? false
                        : true,
                  }),*/
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: 'default',
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 14,
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            </ThemeProvider>
          </CardBody>
        </Card>
      </GridItem>
      {openNew && (
        <NewJobPsychometrics
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      )}
      {openEdit && (
        <EditJobPsychometrics
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      )}
      {openChangeStatus && (
        <ChangeStatusJobsPsychometrics
          open={openChangeStatus}
          exit={handleCloseChangeStatus}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
      {openDesHabilitar && (
        <DisablePsychometrics
          open={openDesHabilitar}
          exit={handleCloseDesHabilitar}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
      {openTestPsychometrics && (
        <SelectTestPsychometrics
          open={openTestPsychometrics}
          exit={handleCloseTestPsychometrics}
          id={idRow}
        />
      )}
      {openTests && (
        <EnableTestJobsPsychometrics
          open={openTests}
          exit={handleCloseTests}
          tests={testsSend}
          id={idRow}
        />
      )}
      {openApplicants && (
        <EnableApplicantJobsPsychometrics
          open={openApplicants}
          exit={handleCloseApplicants}
          tests={tests}
          id={idRow}
        />
      )}
      {openApplicantsTests && (
        <ViewApplicantTestPsychometrics
          open={openApplicantsTests}
          exit={handleCloseApplicantsTests}
          jobsId={idRow.id}
        />
      )}
    </GridContainer>
  )
}
