import React, { useState, useContext, useEffect, Fragment } from 'react'
import { Redirect } from 'react-router-dom'

import { UrlServicesContext } from 'components/UrlServicesContext'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Lock,
  ArrowRightAlt,
  Visibility,
  WatchLater,
  VisibilityOff,
  Dialpad,
} from '@material-ui/icons'
import {
  Avatar,
  Button,
  Grid,
  Box,
  TextField,
  Paper,
  InputLabel,
  Input,
  Hidden,
  Snackbar,
  Slide,
  LinearProgress,
  InputAdornment,
  IconButton,
  FormControl,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Animated } from 'react-animated-css'
import axios from 'axios'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha'

import logo from 'assets/img/logomain.png'
import banner from 'assets/img/polygraph2-min.jpeg'
import bannerLeft from 'assets/img/bannerLeft.webp'

import PasswordRecovery from './dialog/PasswordRecovery'

import {
  cardBodyStyle,
  progressLinear,
} from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)
const ColorLinearProgress = withStyles(progressLinear)(LinearProgress)

export default function Login() {
  const classes = useStyles()

  const [loaderLogin, setLoaderLogin] = useState(false)
  const [validateEmail, setValidateEmail] = useState(false)
  const [passwordRecovery, setpasswordRecovery] = useState(false)
  const [errorFieldLogin, setErrorFieldLogin] = useState(false)
  const [ipPublic, setipPublic] = useState('')
  const [validateCode, setValidateCode] = useState(false)
  const [mins, setMinutes] = useState(3)
  const [secs, setSeconds] = useState(0)

  const [values, setValues] = useState({
    mail: '',
    captcha: '',
    password: '',
    newPassword: '',
    code: '',
    status: '',
    session: '',
    id: '',
    profile_id: '',
    allname: '',
    user: '',
    account_id: '',
    view_password: '',
    userspass: '',
    activateViewPassword: '',
    codInstitucion: '',
    moreAccount: false,
    arrayUser: [],
    errorCode: false,
    showPassword: false,
    errorMail: false,
    errorCaptcha: false,
    errorCodInstitucion: false,
  })

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: '',
    loggedIn: localStorage.getItem('Session') === null ? false : true,
  })

  useEffect(() => {
    axios
      .get(`https://api.ipify.org?format=json`)
      .then(
        (responseIP) => {
          setipPublic(responseIP.data.ip)
        },
        (error) => {
          console.log(error + ' ERROR DE IP')
          setipPublic('0.0.0.0')
        }
      )
      .catch(function (error) {
        // handle error
        console.log(error + ' ERROR DE IP')
        setipPublic('0.0.0.0')
      })

    loadCaptchaEnginge(4, '#bdbdbd', '#000', 'upper')
  }, [])

  useEffect(() => {
    if (validateCode) {
      let sampleInterval = setInterval(() => {
        if (secs > 0) {
          setSeconds(secs - 1)
        }
        if (secs === 0) {
          if (mins !== 0) {
            setMinutes(mins - 1)
            setSeconds(59)
          } else {
            setValidateCode(false)
            setValidateEmail(false)
            setMinutes(3)
            setSeconds(0)
            setValues({
              ...values,
              mail: '',
              captcha: '',
              password: '',
              newPassword: '',
              code: '',
              status: '',
              session: '',
              id: '',
              profile_id: '',
              allname: '',
              user: '',
              account_id: '',
              view_password: '',
              userspass: '',
              activateViewPassword: '',
              codInstitucion: '',
              moreAccount: false,
              arrayUser: [],
            })
            loadCaptchaEnginge(4, '#bdbdbd', '#000', 'upper')
          }
        }
      }, 1000)
      return () => {
        clearInterval(sampleInterval)
      }
    }
  })

  const handleChange = (prop) => (event) => {
    setErrorFieldLogin(false)
    setAlert({
      ...alert,
      openAlert: false,
    })
    setValues({
      ...values,
      [prop]: event.target.value,
      errorCaptcha: false,
      errorMail: false,
      errorCode: false,
      errorCodInstitucion: false,
    })
  }

  const handleClickShowTextFieldPassword = () => () => {
    if (values.codInstitucion.length === 0) {
      setLoaderLogin(false)
      setValues({ ...values, errorCodInstitucion: true })
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'El campo código de institución es obligatorio!',
      })
    } else {
      const userFound = values.arrayUser.find(
        (val) => val.accountId === parseInt(values.codInstitucion)
      )

      if (userFound) {
        setLoaderLogin(true)

        setValues({
          ...values,
          id: userFound.id,
          view_password: userFound.view_password,
          userspass: userFound.userspass,
          activateViewPassword: userFound.activateViewPassword,
          profile_id: userFound.profileId,
        })

        setTimeout(() => {
          setLoaderLogin(false)
          setValidateEmail(true)
        }, 200)
      } else {
        setAlert({
          ...alert,
          mensaje_error: 'El usuario no está en esta institución',
          openAlert: true,
        })
        setValues({
          ...values,
          errorCodInstitucion: true,
        })
      }
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const submitEmail = () => (event) => {
    event.preventDefault()
    setLoaderLogin(true)

    if (values.mail.length === 0) {
      setValues({ ...values, errorMail: true })
      setLoaderLogin(false)
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'Correo electrónico o documento es requerido.',
      })
    } else if (values.captcha.length === 0) {
      setLoaderLogin(false)

      setValues({ ...values, errorCaptcha: true })

      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'Código captcha requerido.',
      })
    } else if (validateCaptcha(values.captcha) !== true) {
      setLoaderLogin(false)

      setValues({ ...values, captcha: '', errorCaptcha: true })

      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'Código no válido del captcha.',
      })
      loadCaptchaEnginge(4, '#bdbdbd', '#000', 'upper')
    } else {
      setValues({ ...values, captcha: '' })

      let data = {
        email: values.mail.toLowerCase().trim(),
      }

      axios
        .post(`${urlServices}auth/email`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length === 1) {
              if (response.data[0].accountStatus === 0) {
                setValues({ ...values, errorMail: true })
                setAlert({
                  ...alert,
                  openAlert: true,
                  mensaje_error:
                    'La cuenta asociada a este correo electrónico esta INACTIVA.',
                })
              } else {
                setValues({
                  ...values,
                  id: response.data[0].id,
                  view_password: response.data[0].view_password,
                  userspass: response.data[0].userspass,
                  activateViewPassword: response.data[0].activateViewPassword,
                  profile_id: response.data[0].profileId,
                  arrayUser:
                    response.data[0].profileId === 6 ? response.data : [],
                  moreAccount: response.data[0].profileId === 6 ? true : false,
                })

                if (response.data[0].profileId !== 6) {
                  setTimeout(() => {
                    setLoaderLogin(false)
                    setValidateEmail(true)
                  }, 200)
                } else {
                  setLoaderLogin(false)
                }
              }
            } else {
              setValues({
                ...values,
                arrayUser: response.data,
                moreAccount: true,
              })
              setLoaderLogin(false)
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setValues({ ...values, errorMail: true })
            setTimeout(() => {
              setLoaderLogin(false)
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error:
                  'Su correo o documento no fueron encontrados o están inactivos en nuestra plataforma.',
              })
            }, 200)
          } else if (e.response.status === 400) {
            setValues({ ...values, errorMail: true })
            setTimeout(() => {
              setLoaderLogin(false)
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: 'El correo electrónico no es válido.',
              })
            }, 200)
          } else {
            setTimeout(() => {
              setLoaderLogin(false)
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: 'Error de conexión, intente más tarde.',
              })
            }, 200)
            console.log(e)
          }
        })
    }
  }

  const submitFormLogin = () => (event) => {
    event.preventDefault()

    setErrorFieldLogin(false)
    setLoaderLogin(true)

    if (values.password.length === 0) {
      setErrorFieldLogin(true)
      setLoaderLogin(false)
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'El campo contraseña es obligatorio!',
      })
    } else {
      const data = {
        userId: values.id,
        email: values.mail.trim(),
        password: values.password,
        localIp: '127.0.0.1',
        publicIp: ipPublic,
      }

      axios
        .post(`${urlServices}auth/login`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setValues({
              ...values,
              status: response.data.user.status,
              session: response.data.jwt,
              user: response.data.user.name,
              id: response.data.user.id,
              allname: response.data.user.username,
              account_id: response.data.user.accountId,
            })

            if (
              response.data.user.profileId === 6 &&
              response.data.user.email !== ''
            ) {
              let mailsend = {
                email: values.mail.toLowerCase().trim(),
              }

              axios
                .post(`${urlServices}users/send-authcode`, mailsend, {
                  headers: {
                    application: keyAplication,
                  },
                })
                .then((responseSendMail) => {
                  if (responseSendMail.status === 200) {
                    setLoaderLogin(false)
                    setValidateCode(true)
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              localStorage.setItem('status', response.data.user.status)
              localStorage.setItem('Session', response.data.jwt)
              localStorage.setItem('user', response.data.user.name)
              localStorage.setItem('id', response.data.user.id)
              localStorage.setItem('profile_id', response.data.user.profileId)
              localStorage.setItem('allname', response.data.user.username)
              localStorage.setItem('account_id', response.data.user.accountId)
              localStorage.setItem('codInstitucion', values.codInstitucion)
              setLoaderLogin(false)
              redirectProgile(response.data.user.profileId)
            }
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setLoaderLogin(false)
            setValidateEmail(false)
            setValues({
              ...values,
              password: '',
              codInstitucion: '',
              moreAccount: false,
              arrayUser: [],
            })
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: 'Contraseña incorrecta!',
            })

            loadCaptchaEnginge(4, '#bdbdbd', '#000', 'upper')
          } else {
            setTimeout(() => {
              setLoaderLogin(false)
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: 'Error de conexión, intente más tarde.',
              })
            }, 200)
            console.log(e)
          }
        })
    }
  }

  const submitFormCode = () => (event) => {
    event.preventDefault()
    setLoaderLogin(true)

    if (values.code.length === 0) {
      setLoaderLogin(false)

      setValues({ ...values, errorCode: true })
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: 'Debe ingresar el código enviado a su correo.',
      })
    } else {
      const dataCode = {
        email: values.mail.toLowerCase().trim(),
        code: values.code,
      }

      axios
        .post(`${urlServices}users/validate-authcode`, dataCode, {
          headers: {
            application: keyAplication,
          },
        })
        .then(
          (response) => {
            if (response.data.resCode) {
              localStorage.setItem('status', values.status)
              localStorage.setItem('Session', values.session)
              localStorage.setItem('user', values.user)
              localStorage.setItem('id', values.id)
              localStorage.setItem('profile_id', values.profile_id)
              localStorage.setItem('allname', values.allname)
              localStorage.setItem('account_id', values.account_id)
              localStorage.setItem('codInstitucion', values.codInstitucion)
              setLoaderLogin(false)
              redirectProgile(values.profile_id)
            } else {
              setLoaderLogin(false)
              setValues({ ...values, errorCode: true })

              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: 'Código ingresado es incorrecto!',
              })
            }
          },
          (error) => {
            setTimeout(() => {
              setLoaderLogin(false)
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: 'Error de conexión, intente más tarde.',
              })
            }, 200)
          }
        )
    }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert({ openAlert: false })
  }

  const handleOpenPasswordRecovery = () => {
    setpasswordRecovery(true)
  }

  const handleClosePasswordRecovery = () => {
    setpasswordRecovery(false)
  }

  const redirectProgile = (profile) => {
    if (profile === 1) {
      localStorage.setItem('controller', '/superadmin')
      localStorage.setItem('controllerRouter', '/superadmin/dashboard')

      setAlert({ ...alert, loggedIn: true })
    } else {
      localStorage.setItem('controller', '/admin')
      localStorage.setItem('controllerRouter', '/admin/dashboard')

      setAlert({ ...alert, loggedIn: true })
    }
  }

  if (alert.loggedIn) {
    return (
      <Redirect
        from={localStorage.getItem('controller')}
        to={localStorage.getItem('controllerRouter')}
      />
    )
  }

  return (
    <Grid container component="main" style={{ padding: '4% 10% 3% 10%' }}>
      <Hidden xsDown smDown>
        <Grid
          item
          xs={false}
          md={6}
          component={Paper}
          elevation={5}
          square
          /*style={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          style={{
            backgroundImage: 'url('+bannerLeft+')',
            backgroundRepeat: 'no-repeat',           
            backgroundSize: "300px 300px",
            backgroundPosition: 'center',  
            backgroundColor: "#FFF",      
          }}*/
          style={{
            backgroundImage: 'url(' + bannerLeft + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '40px 10px 10px 50px',
            backgroundColor: '#FFFFFF',
            borderTopLeftRadius: 20,
          }}
        >
          <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            isVisible={true}
          >
            <center>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: 200,
                  marginTop: 150,
                  marginBottom: 10,
                  objectFit: 'contain',
                }}
              />
              <br></br>
              <span className={classes.subtextCard}>
                PLATAFORMA DE ADMINISTRACIÓN DE PRUEBAS
              </span>
              {/* <img
                src={img}
                alt="validate"
                style={{
                  width: 20,
                  marginTop: 35,
                  marginBottom: 20,
                  objectFit: "contain",
                }}
              />*/}
              <br></br>
              <div style={{ fontSize: 11, paddingTop: 210 }}>
                <b>POLYGRAPH - {1900 + new Date().getYear()} COPYRIGHT&copy;</b>
              </div>
            </center>
          </Animated>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        md={6}
        component={Paper}
        elevation={5}
        square
        style={{
          borderBottomRightRadius: 20,
          overflow: 'hidden',
        }}
      >
        {loaderLogin ? <ColorLinearProgress /> : ''}

        <Box>
          <img
            src={banner}
            alt="banner"
            style={{
              width: '100%',
              height: 50,
              objectFit: 'cover',
            }}
          />
        </Box>
        <Box
          style={{
            padding: '20px 20px 50px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: loaderLogin ? '0.4' : 1,
          }}
        >
          <Hidden mdUp>
            <center>
              <img
                src={logo}
                alt="validate"
                style={{
                  width: 150,
                  marginTop: 10,
                  marginBottom: 20,
                  objectFit: 'contain',
                }}
              />
            </center>
          </Hidden>
          <Avatar>
            <Lock style={{ color: '#000' }} />
          </Avatar>
          <div className={classes.titleCard}>Acceder</div>
          <Box
            component="form"
            noValidate
            //  onSubmit={handleSubmit}
            style={{ width: '85%' }}
          >
            {!validateCode ? (
              <Fragment>
                {!validateEmail ? (
                  <Animated
                    animationIn="slideInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <TextField
                      autoComplete="off"
                      name="user"
                      value={values.mail}
                      onChange={handleChange('mail')}
                      label="Correo electrónico o Documento"
                      error={values.errorMail}
                      className={classes.textField}
                      type="email"
                      fullWidth
                      style={{ marginLeft: '8%' }}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                        },
                        maxLength: 150,
                      }}
                      variant="outlined"
                      disabled={values.moreAccount}
                    />
                    {values.moreAccount ? (
                      <Grid container spacing={3} style={{ padding: '30px 0' }}>
                        <Grid item xs={12} md={12}>
                          <center>
                            <Alert
                              severity="warning"
                              style={{ marginLeft: '3%' }}
                            >
                              Ingrese el código de la institución.
                            </Alert>
                            <TextField
                              id="codInstitucion"
                              label="Código institución"
                              variant="outlined"
                              value={values.codInstitucion}
                              error={values.errorCodInstitucion}
                              onChange={handleChange('codInstitucion')}
                              type="number"
                              style={{ width: 200 }}
                              margin="dense"
                              inputProps={{
                                style: { textAlign: 'center', fontSize: 20 },
                              }}
                              fullWidth
                              autoComplete="off"
                            />
                          </center>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <center>
                            <Button
                              className={classes.buttonSubmit}
                              onClick={handleClickShowTextFieldPassword()}
                              size="large"
                            >
                              Validar institución
                            </Button>
                          </center>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          spacing={3}
                          style={{ padding: '30px 15px' }}
                        >
                          <Grid item xs={6} md={6}>
                            <div style={{ float: 'right' }}>
                              <LoadCanvasTemplate
                                reloadColor="#024242"
                                reloadText="Actualizar"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={6} md={6}>
                            <TextField
                              id="user_captcha_input"
                              name="captcha"
                              value={values.captcha}
                              onChange={handleChange('captcha')}
                              label="Código"
                              variant="outlined"
                              error={values.errorCaptcha}
                              margin="dense"
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                  width: 100,
                                },
                                maxLength: 4,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <center>
                              <Button
                                className={classes.buttonSubmit}
                                onClick={submitEmail()}
                                size="large"
                                endIcon={<ArrowRightAlt />}
                              >
                                Siguiente
                              </Button>
                            </center>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Animated>
                ) : (
                  <Fragment>
                    <form onSubmit={submitFormLogin()}>
                      <Animated
                        animationIn="slideInLeft"
                        animationInDuration={500}
                        isVisible={true}
                      >
                        <center>
                          {values.view_password === 1 &&
                            values.activateViewPassword === 1 && (
                              <Alert severity="warning">
                                Ingrese esta contraseña:&nbsp;&nbsp;
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {values.userspass}
                                </span>
                              </Alert>
                            )}

                          <FormControl className={classes.textField}>
                            <InputLabel htmlFor="standard-adornment-password">
                              Contraseña
                            </InputLabel>
                            <Input
                              autoFocus
                              name="password"
                              type={values.showPassword ? 'text' : 'password'}
                              value={values.password}
                              onChange={handleChange('password')}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {values.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              error={errorFieldLogin}
                            />
                          </FormControl>
                          <br></br>
                          <br></br>
                          <Button
                            className={classes.buttonSubmit}
                            onClick={submitFormLogin()}
                            color="primary"
                            size="large"
                          >
                            Iniciar sesión
                          </Button>
                        </center>
                      </Animated>
                    </form>

                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <center>
                          <Button
                            className={classes.textBotom}
                            aria-haspopup="true"
                            onClick={handleOpenPasswordRecovery}
                            style={loaderLogin ? { opacity: '0.4' } : null}
                          >
                            Recuperar contraseña
                          </Button>
                        </center>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <form onSubmit={submitFormCode()}>
                  <Animated
                    animationIn="slideInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <center>
                      <Dialpad style={{ fontSize: '42px' }} />
                      <Box style={{ marginBottom: 10 }}>
                        <TextField
                          autoComplete="off"
                          name="code"
                          type="number"
                          value={values.code}
                          error={values.errorCode}
                          onChange={handleChange('code')}
                          required
                          label="Código de validación"
                          margin="normal"
                          inputProps={{
                            style: { textAlign: 'center', fontSize: 24 },
                          }}
                          fullWidth
                        />
                        <Alert severity="warning">
                          El código fue enviado a su correo electrónico, revise
                          su bandeja de recibidos o correos no deseados (SPAM).
                        </Alert>
                      </Box>
                      <Button
                        onClick={submitFormCode()}
                        size="large"
                        className={classes.buttonSubmit2}
                      >
                        Validar código
                      </Button>
                    </center>
                  </Animated>
                </form>
                <br></br>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <center>
                      <span className={classes.cardTitleDialog}>
                        <WatchLater
                          fontSize="small"
                          className={classes.iconTheme}
                        />{' '}
                        <small style={{ color: 'black' }}>
                          {(mins < 10 ? '0' + mins : mins) +
                            ':' +
                            (secs < 10 ? '0' + secs : secs)}
                        </small>
                      </span>
                    </center>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Box>
        </Box>
      </Grid>

      {alert.openAlert && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      )}

      {passwordRecovery && (
        <PasswordRecovery
          open={passwordRecovery}
          exit={handleClosePasswordRecovery}
        />
      )}
    </Grid>
  )
}
