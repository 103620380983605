import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import { Redirect } from 'react-router-dom'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Assignment, Search, Print } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Backdrop,
  CircularProgress,
  Button,
  Snackbar,
  Slide,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'

import LoadTestAccount from 'components/services/LoadTestAccount'
import ExportApplicantPassTestExcel from 'views/Print/ExportApplicantPassTestExcel'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function GeneratePassTest() {
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [resultTotal, setResultTotal] = useState(0)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const accountId = localStorage.getItem('account_id')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    testAccount: '',
    testAccountData: [],
    errorTestAccount: false,
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const submitAdd = (e) => {
    e.preventDefault()
    setoOpenBackdrop(true)

    if (values.testAccount !== '') {
      axios
        .get(`${urlServices}tests-users/test/${values.testAccount}`, {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setRows(response.data)
            setResultTotal(response.data.length)
            setoOpenBackdrop(false)
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setTimeout(() => {
              localStorage.clear()
              setReturnLogin(true)
            }, 200)
          } else {
            console.log(e)
          }
        })
    } else {
      setMensaje('Debe seleccionar una prueba.')
      setOpenAlert(true)
      setError('error')
      setoOpenBackdrop(false)
    }
  }

  const changeTestAccount = (pro) => {
    setValues({
      ...values,
      testAccount: pro.id,
      testAccountData: pro,
    })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Assignment className={classes.iconWhite} /> Generar claves a
              candidatos
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <LoadTestAccount
                  refresh={changeTestAccount}
                  value={values.testAccountData}
                  error={values.errorTestAccount}
                />
              </GridItem>

              <GridItem xs={12} md={2}>
                <Button
                  className={classes.buttonLeft}
                  onClick={submitAdd}
                  startIcon={<Search />}
                >
                  Buscar
                </Button>
              </GridItem>
            </GridContainer>

            {rows.length > 0 ? (
              <Fragment>
                <br></br>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableCell
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      RESULTADO: {resultTotal} registro
                      {resultTotal > 1 ? 's' : ''}
                      &nbsp;&nbsp;
                      <Button
                        type="submit"
                        className={classes.buttonSubmit1Warninf}
                        href={`/passapplicants?test=${values.testAccount}&cod=${accountId}`}
                        startIcon={<Print style={{ fontSize: 14 }} />}
                        component="a"
                        target="_blank"
                      >
                        PDF
                      </Button>
                      <ExportApplicantPassTestExcel
                        data={rows}
                        style={classes.buttonSubmit1}
                      />
                    </TableCell>
                  </TableBody>
                </Table>
                <br></br>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableBody>
                    <TableRow className={classes.titleFilterAction}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          width: 350,
                          backgroundColor: '#F2F2F2',
                        }}
                      >
                        Nombres y apellidos
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: 'bold',
                          fontSize: 14,
                          backgroundColor: '#F2F2F2',
                        }}
                      >
                        Documento
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 14,
                          backgroundColor: '#F2F2F2',
                        }}
                      >
                        Código institución
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontSize: 14,
                          backgroundColor: '#F2F2F2',
                        }}
                      >
                        Contraseña
                      </TableCell>
                    </TableRow>
                    {rows.map((row1) => {
                      return (
                        <TableRow className={classes.titleFilterAction}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontSize: 12,
                              width: 350,
                            }}
                          >
                            {row1.user.name + ' ' + row1.user.lastName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontSize: 12,
                              width: 300,
                            }}
                          >
                            {row1.user.documentId}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontSize: 12,
                              width: 100,
                              textAlign: 'center',
                            }}
                          >
                            {accountId}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: '600',
                              textAlign: 'center',
                              fontSize: 12,
                            }}
                          >
                            {row1.user.userspasswords.length === 0 ? (
                              <b style={{ color: 'red' }}>SIN CLAVE</b>
                            ) : (
                              row1.user.userspasswords[0].password
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Fragment>
            ) : (
              <div
                style={{
                  color: '#C5C5C5',
                  padding: '60px 0',
                  fontWeight: 'bold',
                }}
              >
                <center> No hay resultados </center>
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error === 'error' ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  )
}
