import React, { useState, forwardRef, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Search,
  Cancel,
  Layers,
  AddCircle,
  PersonAdd,
  SendOutlined,
  ScheduleOutlined,
} from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import { Grid, Button, LinearProgress, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import format from 'date-fns/format'

import NewTestMain from 'views/dialog/NewTestMain'
import ViewTest from 'views/dialog/ViewTest'
import Questions from 'views/dialog/Questions'
import EditTest from 'views/dialog/EditTests'
import EnableApplicantTest from 'views/dialog/EnableApplicantTest'
import TestsQuestions from 'views/AdminInstitution/TestsQuestions'
import ChangeStatusTest from 'views/dialog/ChangeStatusTest'
import DisableTest from 'views/dialog/DisableTest'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

export default function Test() {
  const classes = useStyles()
  const tableRef = React.createRef()
  const classes2 = useStyles2()

  const [openView, setOpenView] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openNew, setOpenNew] = useState(false)
  const [openEnableQuestion, setOpenEnableQuestion] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openApplicants, setOpenApplicants] = useState(false)
  const [openTestQuestion, setOpenTestQuestion] = useState(false)
  const [openDesHabilitar, setOpenDesHabilitar] = useState(false)
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [activatePhoto, setActivatePhoto] = useState(false)
  const [activateApplicantreport, setActivateApplicantreport] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const accountId = localStorage.getItem('account_id')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Prueba',
        field: 'name',
        width: '35%',
        render: (rowData) => {
          return <b>{rowData.name}</b>
        },
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Cargo',
        field: 'testjob.name',
        width: '35%',
        render: (rowData) => {
          return rowData.testjob[0].name
        },
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Preguntas',
        field: 'name',
        render: (rowData) => (
          <Button
            className={classes.buttonSubmit1}
            onClick={() => OpenTestQuestion(rowData)}
            startIcon={<Search style={{ fontSize: 14 }} />}
          >
            {rowData.question.length}
          </Button>
        ),
        width: '1%',
        cellStyle: {
          textAlign: 'center',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: 'Registrado',
        render: (rowData) => format(new Date(rowData.createdAt), 'dd-MM-yyyy'),
        width: '15%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'FINALIZAR'
              : rowData.status === 99
              ? 'PUBLICAR'
              : 'FINALIZADO'
          const color =
            rowData.status === 1
              ? '#024242'
              : rowData.status === 99
              ? 'orange'
              : ''
          return rowData.status === 2 ? (
            <Alert
              icon={false}
              severity="success"
              style={{ fontSize: 10, fontWeight: 'bold' }}
            >
              FINALIZADO
            </Alert>
          ) : (
            <>
              <Button
                variant="contained"
                style={{
                  fontSize: 10,
                  background: color,
                  color: '#fff',
                  fontWeight: 'bold',
                  padding: '4px 10px',
                }}
                endIcon={
                  rowData.status === 99 ? (
                    <SendOutlined />
                  ) : (
                    <ScheduleOutlined />
                  )
                }
                onClick={() =>
                  publishSend(rowData, rowData.status === 99 ? 1 : 2)
                }
              >
                {text}
              </Button>
              <Box
                style={{
                  display: rowData.status === 99 ? 'none' : 'block',
                  marginTop: 5,
                }}
              >
                <b>En proceso</b>
                <LinearProgress />
              </Box>
            </>
          )
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
      {
        title: '---',
        field: 'id',
        render: (rowData) => (
          <Button
            className={classes.buttonSubmit4}
            onClick={() => OpenQuestion(rowData)}
            disabled={
              rowData.status === 1 ? true : rowData.status === 99 ? false : true
            }
            startIcon={<AddCircle />}
          >
            preguntas
          </Button>
        ),
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 10,
        },
        sorting: false,
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const OpenQuestion = (data) => {
    setIdRow(data)
    setOpenEnableQuestion(true)
  }

  const OpenTestQuestion = (data) => {
    setIdRow(data)
    setOpenTestQuestion(true)
  }

  const handleCloseNew = () => {
    setOpenNew(false)
  }

  const handleCloseView = () => {
    setOpenView(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const handleCloseEnableQuestion = () => {
    setOpenEnableQuestion(false)
  }

  const handleCloseApplicant = () => {
    setOpenApplicants(false)
  }

  const handleCloseTestQuestions = () => {
    setOpenTestQuestion(false)
  }

  const handleCloseDesHabilitar = () => {
    setOpenDesHabilitar(false)
  }

  const publishSend = (data, status) => {
    setOpenChangeStatus(true)
    setIdRow({ data: data, status: status })
  }

  const handleCloseChangeStatus = () => {
    setOpenChangeStatus(false)
  }

  const handleOpenTest = (event, option, rowData) => {
    const urlAccount = `${urlServices}accounts/${accountId}`

    fetch(urlAccount, {
      method: 'get',
      headers: {
        application: keyAplication,
        Authorization: 'Bearer ' + keyAuthorization,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result !== undefined) {
          setActivatePhoto(result.activatePhoto)
          setActivateApplicantreport(result.activateApplicantreport)
          if (option === 1) setOpenNew(true)
          else {
            setIdRow(rowData)
            setOpenEdit(true)
          }
        }
      })
  }

  let url = ''

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Gestión de pruebas
              ténicas
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={6} sm={6} style={{ fontSize: 16 }}>
                <b>Código institución: </b>{' '}
                <span
                  style={{
                    fontSize: 18,
                    padding: 10,
                    width: 50,
                    background: 'rgb(255, 244, 229)',
                    borderRadius: 50,
                    color: 'rgb(102, 60, 0)',
                    fontWeight: 'bold',
                  }}
                >
                  {accountId}
                </span>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  className={classes.buttonRight}
                  onClick={(e) => handleOpenTest(e, 1)}
                  startIcon={<AddCircle />}
                >
                  Nueva prueba
                </Button>
              </Grid>
            </Grid>
            <MaterialTable
              title=""
              columns={state.columns}
              tableRef={tableRef}
              style={{
                boxShadow: 'unset',
                border: '1px solid rgb(241, 241, 241)',
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  if (query.search === '') {
                    url = `${urlServices}tests/account/${accountId}?limit=${
                      query.pageSize
                    }&page=${query.page + 1}`
                  } else {
                    url = `${urlServices}tests/account/${accountId}`
                  }
                  fetch(url, {
                    method: 'get',
                    headers: {
                      application: keyAplication,
                      Authorization: 'Bearer ' + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        //data: result.data,
                        data:
                          result.data !== undefined
                            ? result.data.filter(function (obj) {
                                return Object.keys(obj).some(function (key) {
                                  return obj[key]
                                    ? obj[key]
                                        .toString()
                                        .toLowerCase()
                                        .includes(query.search)
                                    : false
                                })
                              })
                            : [],
                        page: result.page !== undefined ? result.page - 1 : 0,
                        totalCount:
                          result.total !== undefined ? result.total : 0,
                      })
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                })
              }
              localization={{
                pagination: {
                  labelRowsSelect: 'Filas',
                  labelDisplayedRows: ' {from}-{to} de {count}',
                  firstTooltip: 'Primera página',
                  previousTooltip: 'Previo',
                  nextTooltip: 'Siguiente',
                  lastTooltip: 'Ultima página',
                },
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: 'Filtrar',
                  searchPlaceholder: 'Buscar',
                },
                header: {
                  actions: ' --- ',
                },
                body: {
                  emptyDataSourceMessage: 'No hay datos.',
                  filterRow: {
                    filterTooltip: 'Filtro',
                  },
                },
              }}
              actions={[
                (rowData1) => ({
                  icon: () => <PersonAdd />,
                  tooltip: 'Agregar candidatos',
                  onClick: (event, rowData) => {
                    setOpenApplicants(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                {
                  icon: 'search',
                  tooltip: 'Ver',
                  onClick: (event, rowData) => {
                    setOpenView(true)
                    setIdRow(rowData)
                  },
                },
                (rowData1) => ({
                  icon: 'edit',
                  tooltip: 'Editar',
                  onClick: (event, rowData) =>
                    handleOpenTest(event, 2, rowData),
                  hidden:
                    rowData1.status === 1 || rowData1.status === 2
                      ? true
                      : false,
                }),
                (rowData1) => ({
                  icon: () => <Cancel />,
                  tooltip: 'Eliminar',
                  onClick: (event, rowData) => {
                    setOpenDesHabilitar(true)
                    setIdRow(rowData)
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                /*(rowData2) => ({
                  icon: () => (
                    <AssignmentTurnedInIcon style={{ color: 'green' }} />
                  ),
                  tooltip: 'Publicar',
                  onClick: (event, rowData) => {
                    setOpenHabilitar(true)
                    setIdRow(rowData)
                  },
                  hidden:
                    rowData2.status === 0 || rowData2.status === 99
                      ? false
                      : true,
                }),*/
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              icons={tableIcons}
              options={{
                search: true,
                padding: 'default',
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                headerStyle: {
                  background: '#F2F2F2',
                  padding: '1%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                },
                cellStyle: { border: '1px solid #DDDDDD' },
              }}
              components={{
                Row: (props) => (
                  <MTableBodyRow className={classes2.tableRow} {...props} />
                ),
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openNew && (
        <NewTestMain
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      )}
      {openView && (
        <ViewTest open={openView} id={idRow} exit={handleCloseView} />
      )}
      {openEnableQuestion && (
        <Questions
          open={openEnableQuestion}
          exit={handleCloseEnableQuestion}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
      {openEdit && (
        <EditTest
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
          activatePhoto={activatePhoto}
          activateApplicantreport={activateApplicantreport}
        />
      )}
      {openApplicants && (
        <EnableApplicantTest
          open={openApplicants}
          exit={handleCloseApplicant}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
      {openTestQuestion && (
        <TestsQuestions
          open={openTestQuestion}
          exit={handleCloseTestQuestions}
          id={idRow}
        />
      )}
      {openChangeStatus && (
        <ChangeStatusTest
          open={openChangeStatus}
          exit={handleCloseChangeStatus}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
      {openDesHabilitar && (
        <DisableTest
          open={openDesHabilitar}
          exit={handleCloseDesHabilitar}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
    </GridContainer>
  )
}
