import React, {
  useState,
  forwardRef,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, AccessTime, ArrowRightAlt } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import { Grid, Button } from '@material-ui/core'
import format from 'date-fns/format'

import StartTest from 'views/dialog/StartTest'
import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)
const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(255, 244, 229)!important' } },
})

const PendingTestsList = (props) => {
  const classes = useStyles()
  const classes2 = useStyles2()

  const [idRow, setIdRow] = useState('')
  const [rows, setRows] = useState([])
  const [openEnable, setOpenEnable] = useState(false)
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [returnLogin, setReturnLogin] = useState(false)

  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const columns = [
    {
      title: 'Prueba',
      field: 'test.name',
      width: '45%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 11,
        // fontWeight: 'bold',
      },
    },
    {
      title: 'Cargo',
      field: 'test.testjob[0].name',
      width: '45%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 11,
        //  fontWeight: 'bold',
      },
    },
    {
      title: 'Presentar',
      field: '',
      render: (rowData) => {
        const nameButton =
          rowData.status === 1
            ? 'Iniciar'
            : rowData.status === 2
            ? 'Continuar'
            : 'Pausado'
        const css =
          rowData.status === 1
            ? classes.buttonSubmit6
            : rowData.status === 2
            ? classes.buttonSubmit5
            : ''
        const startDateNull = rowData.test.activationTime
        const fechaInicio = startDateNull
          ? new Date(
              format(new Date(rowData.test.activationStart), 'yyyy-MM-dd HH:mm')
            ).getTime()
          : ''
        const fechaFin = startDateNull
          ? new Date(
              format(new Date(rowData.test.activationEnd), 'yyyy-MM-dd HH:mm')
            ).getTime()
          : ''
        const currentDate = new Date(
          format(new Date(), 'yyyy-MM-dd HH:mm')
        ).getTime()
        const dateCounterFinal = startDateNull
          ? currentDate >= fechaInicio && currentDate <= fechaFin
          : false

        return startDateNull ? (
          dateCounterFinal ? (
            <Button
              className={css}
              onClick={
                rowData.status !== 4 ? () => OpenEnable(rowData) : undefined
              }
              endIcon={
                rowData.status === 1 ? (
                  <AccessTime
                    style={{ verticalAlign: 'middle', fontSize: 16 }}
                  />
                ) : rowData.status === 2 ? (
                  <ArrowRightAlt
                    style={{ verticalAlign: 'middle', fontSize: 16 }}
                  />
                ) : undefined
              }
            >
              {nameButton}
            </Button>
          ) : (
            <b>No disponible</b>
          )
        ) : (
          <Button
            className={css}
            onClick={
              rowData.status !== 4 ? () => OpenEnable(rowData) : undefined
            }
            endIcon={
              rowData.status === 1 ? (
                <AccessTime style={{ verticalAlign: 'middle', fontSize: 16 }} />
              ) : rowData.status === 2 ? (
                <ArrowRightAlt
                  style={{ verticalAlign: 'middle', fontSize: 16 }}
                />
              ) : undefined
            }
          >
            {nameButton}
          </Button>
        )
      },
      width: '5%',
      cellStyle: {
        textAlign: 'CENTER',
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
      sorting: false,
    },
  ]

  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${urlServices}tests-users/user/${localStorage.getItem('id')}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )

      if (response.status === 200) {
        const result = response.data.filter(
          (filtered) =>
            (filtered.status === 1 ||
              filtered.status === 2 ||
              filtered.status === 4) &&
            filtered.test.status === 1 &&
            parseInt(localStorage.getItem('codInstitucion')) ===
              parseInt(filtered.test.user.accountId)
        )
        setRows(result)
      }
    } catch (e) {
      if (e.response.status === 404) {
        setRows([])
      } else if (e.response.status === 401) {
        localStorage.clear()
        setReturnLogin(true)
      } else {
        setError(true)
        console.log(e)
      }
    } finally {
      setLoader(false)
    }
  }, [urlServices, keyAplication, keyAuthorization])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const RefreshTable = useCallback(() => {
    fetchData()
    props.callBackTestDone()
  }, [fetchData, props])

  const handleClose = () => {
    setOpenEnable(false)
  }

  const OpenEnable = (data) => {
    setIdRow({
      id: data.id,
      testId: data.testId,
      user: data.user,
      status: data.status,
      test: data.test,
      takePhoto: data.test.takePhoto,
    })
    setOpenEnable(true)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader variant="filled" severity="warning">
            <Alert variant="filled" severity="warning">
              <b style={{ fontSize: 12, marginTop: 5 }}>PRUEBAS PENDIENTES</b>
            </Alert>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert severity="error">
                Error en la consulta con el servidor.
              </Alert>
            )}
            {loader ? (
              <Grid container spacing={2}>
                <GridItem xs={12} sm={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{
                        width: 40,
                      }}
                    />
                  </center>
                </GridItem>
              </Grid>
            ) : (
              <MaterialTable
                title=""
                columns={columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={rows}
                localization={{
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: '{from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Última página',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay pruebas pendientes.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                icons={tableIcons}
                options={{
                  search: false,
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  paging: false,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: 12,
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                  toolbar: false,
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openEnable && (
        <StartTest
          open={openEnable}
          exit={handleClose}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
    </GridContainer>
  )
}

export default PendingTestsList
