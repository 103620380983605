import React, { useRef, useContext, Fragment, useState } from 'react'

// material-ui components
import Webcam from 'react-webcam'
import { Alert } from '@material-ui/lab'
import { Slide, Snackbar } from '@material-ui/core'

import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

export default function Camera(props) {
  const [returnLogin, setReturnLogin] = useState(false)
  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('success')

  const videoConstraints = {
    width: 320,
    height: 240,
    //aspectRatio: 0.6666666667,
    facingMode: 'user',
  }

  const blackPicture =
    'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCADwAUADASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AJVAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//9k='
  const webcamRef = useRef(null)
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot()

    if (imageSrc && blackPicture !== imageSrc) {
      const timeBase = props.tpmTest ? 1200 : (props.data.test.time / 2) * 60
      // 1800 = 30Min
      // 1200 = 20Min
      const time = Math.floor(Math.random() * timeBase) + 1

      setTimeout(() => {
        try {
          const imageSrc = webcamRef.current.getScreenshot()
          props.tpmTest
            ? insertSavePhotoTpm(imageSrc, props.data)
            : insertSavePhoto(imageSrc, props.data)
          capture()
        } catch (error) {}
      }, time * 1000)
    } else {
      setMensaje('No dispone de permisos para acceder a la cámara.')
      setOpenAlert(true)
      setError('error')
      props.userMedia('error')
    }
  }

  const userMediaError = () => {
    setMensaje('No dispone de permisos para acceder a la cámara.')
    setOpenAlert(true)
    setError('error')
    props.userMedia('error')
  }

  const insertSavePhoto = (photo, testUser) => {
    const data = {
      picture: photo,
      testUserId: testUser.id,
    }

    axios
      .post(`${urlServices}tests-users-photos`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          return true
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        }
      })
  }

  const insertSavePhotoTpm = (photo, jobUser) => {
    const data = {
      picture: photo,
      jobUserId: jobUser.id,
    }

    axios
      .post(`${urlServices}jobs-users-photos`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          return true
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        }
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity={error}
            elevation={6}
            variant="filled"
          >
            {mensaje}
          </Alert>
        </Slide>
      </Snackbar>
      <Webcam
        muted={false}
        audio={false}
        mirrored={true}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        style={{
          visibility: 'hidden',
          position: 'absolute',
        }}
        onUserMedia={() =>
          setTimeout(() => {
            capture()
          }, 1000)
        }
        onUserMediaError={userMediaError}
      />
    </Fragment>
  )
}
