import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Cancel, Layers, AddCircle } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable from 'material-table'
import {
  Grid,
  Box,
  Backdrop,
  CircularProgress,
  Button,
} from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import format from 'date-fns/format'

import NewJob from 'views/dialog/NewJob'
import EditJob from 'views/dialog/EditJob'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function Jobs() {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Registrado',
        render: (rowData) =>
          format(new Date(rowData.createdAt), 'dd-MM-yyyy kk:mm:ss'),
        width: '10%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        render: (rowData) => {
          const text = rowData.status === 1 ? 'ACTIVO' : 'INACTIVO'
          const color = rowData.status === 1 ? 'green' : 'red'
          return <b style={{ color: color }}>{text}</b>
        },
        width: '5%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    axios
      .get(`${urlServices}jobs/account/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data)
          setLoader(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setoOpenBackdrop(false)

          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setoOpenBackdrop(false)
          console.log(e)
          setRows([])
          setError(true)
        }
      })
  }, [keyAplication, urlServices, keyAuthorization])

  const RefreshTable = () => {
    setoOpenBackdrop(true)

    axios
      .get(`${urlServices}jobs/account/${localStorage.getItem('account_id')}`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setoOpenBackdrop(false)

          setRows(response.data)
          setoOpenBackdrop(false)
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setoOpenBackdrop(false)

          setRows([])
          setLoader(false)
        } else if (e.response.status === 401) {
          setoOpenBackdrop(false)

          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setoOpenBackdrop(false)

          console.log(e)
          setRows([])
          setError(true)
        }
      })
  }

  const handleCloseNew = () => {
    setOpenNew(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Listado de Cargos
            </div>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: '100%' }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: 'right' }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className={classes.buttonRight}
                      onClick={(event) => {
                        setOpenNew(true)
                      }}
                      startIcon={<AddCircle />}
                    >
                      Agregar cargo
                    </Button>
                  </Grid>
                </Grid>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  style={{
                    boxShadow: 'unset',
                    border: '1px solid rgb(241, 241, 241)',
                  }}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: 'Filas',
                      labelDisplayedRows: ' {from}-{to} de {count}',
                      firstTooltip: 'Primera página',
                      previousTooltip: 'Previo',
                      nextTooltip: 'Siguiente',
                      lastTooltip: 'Ultima página',
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected',
                      searchTooltip: 'Filtrar',
                      searchPlaceholder: 'Buscar',
                    },
                    header: {
                      actions: ' --- ',
                    },
                    body: {
                      emptyDataSourceMessage: 'No hay datos.',
                      filterRow: {
                        filterTooltip: 'Filtro',
                      },
                    },
                  }}
                  actions={[
                    /* {
                        icon: "search",
                        tooltip: "Ver",
                        onClick: (event, rowData) => {
                          setopenViewUser(true);
                          setIdRow(rowData);
                        },
                      },*/
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, rowData) => {
                        setOpenEdit(true)
                        setIdRow(rowData)
                      },
                    },
                    /*{
                          icon: "delete",
                          tooltip: "Eliminar",
                          onClick: (event, rowData) => {
                            setOpenDelete(true);
                            setIdRow(rowData);
                          },
                        },*/
                    (rowData1) => ({
                      icon: () => <Cancel />,
                      tooltip: 'Eliminar',
                      /* onClick: (event, rowData) => {
                        setOpenDelete(true);
                        setIdRow(rowData);
                      },*/
                      hidden: rowData1.status === 1 ? false : true,
                    }),
                    {
                      icon: 'refresh',
                      tooltip: 'Refresh Data',
                      isFreeAction: true,
                      onClick: () => RefreshTable(),
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: 'default',
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: '#F2F2F2',
                      padding: '1%',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    },
                    cellStyle: { border: '1px solid #DDDDDD' },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openNew && (
        <NewJob
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
        />
      )}
      {openEdit && (
        <EditJob
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}

      <Backdrop
        style={{ zIndex: '9999', color: '#FFFFFF' }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  )
}
