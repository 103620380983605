import React, { Fragment, useState, useContext, useEffect } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import FormControl from '@material-ui/core/FormControl'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import {
  BorderColor,
  ArrowRightAlt,
  Save,
  WatchLater,
  Replay,
  Person,
} from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { Animated } from 'react-animated-css'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Alert, AlertTitle } from '@material-ui/lab'
import successImg from 'assets/img/success.gif'
import test from 'assets/img/undraw_Speed_test_re_pe1f.png'
import exit from 'assets/img/undraw_towing_6yy4.png'
import Slide from '@material-ui/core/Slide'
import Camera from 'views/Camera/Camera'

//import CameraVideo from 'views/Camera/CameraVideo'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

export default function StartTest(props) {
  const classes = useStyles()

  const [next, setNext] = useState(0)
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [disabledNext, setDisabledNext] = useState(false)
  const [testResult, setTestResult] = useState([])
  const [mensageExit, setMensageExit] = useState(false)
  const [user, setUser] = useState('')
  const [colorTimer, setColorTimer] = useState('rgb(34, 34, 34)')
  // const [audioQuestion, setAudioQuestion] = useState(false)
  // const [audioQuestionStart, setAudioQuestionStart] = useState(0)
  // const [videoStart, setVideoStart] = useState(0)
  const [videoAuto, setVideoAuto] = useState(false)
  const [timerSave, setTimerSave] = useState(0)
  const [questionTotal, setQuestionTotal] = useState(0)
  const [insertNext, setInsertNext] = useState(false)
  const [disabledBack, setDisabledBack] = useState(true)
  const [returnLogin, setReturnLogin] = useState(false)
  const [question, setQuestion] = useState('')
  const [mensageTestSTOP, setMensageTestSTOP] = useState(false)

  const [hrs, setHrs] = useState(0)
  const [mins, setMinutes] = useState(0)
  const [secs, setSeconds] = useState(0)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    limit: 1,
    offset: 0,
    radioValue: null,
    timer: 0,
  })

  const [error, setError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [cameraInit, setCameraInit] = useState(true)
  const [testCamera, setTestCamera] = useState(true)
  const [photo, setPhoto] = useState(null)
  const [cameraKey, setCameraKey] = useState(1)
  const [userMediaError, setUserMediaError] = useState(false)

  useEffect(() => {
    axios
      .get(`${urlServices}tests/${props.id.testId}/random`, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then((response) => {
        setRows(response.data)
        setTimerSave(response.data.time)
        setQuestionTotal(response.data.question.length)

        let timerFinal =
          parseFloat(response.data.time) - parseFloat(response.data.timer)

        setTotalPages(Math.ceil(response.data.question.length / 1))

        let secData = timerFinal * 60,
          horas = Math.floor(secData / 3600),
          minutos = Math.floor((secData / 60) % 60),
          segundos = secData % 60

        setHrs(horas)
        setMinutes(minutos)
        setSeconds(segundos)

        setUser({
          name: props.id.user.name + ' ' + props.id.user.lastName,
          document: props.id.user.documentId,
        })
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          setRows([])
        }
      })
  }, [keyAplication, urlServices])

  /*useEffect(() => {
    if (audioQuestionStart > 0) {
      let n = 0;
      let sampleIntervalN = setInterval(() => {
        if (n >= audioQuestionStart) {
          setAudioQuestion(false);
          setAudioQuestionStart(0);
        } else {
          n++;
        }
      }, 1000);
      return () => {
        clearInterval(sampleIntervalN);
      };
    }

    if (videoStart > 0) {
      let n = 0,
        videoStartValue = parseFloat(videoStart) + 5;
      let sampleIntervalN2 = setInterval(() => {
        if (n >= videoStartValue) {
          setVideoAuto(false);
          setVideoStart(0);
        } else {
          n++;
        }
      }, 1000);
      return () => {
        clearInterval(sampleIntervalN2);
      };
    }
  }, [audioQuestionStart, videoStart]);*/

  useEffect(() => {
    if (next > 0) {
      let sampleInterval = setInterval(() => {
        if (secs > 0) {
          setSeconds(secs - 1)
        }
        if (secs === 0) {
          if (mins === 0) {
            if (hrs === 0) {
              clearInterval(sampleInterval)
              setNext(totalPages + 1)
            } else {
              setHrs(hrs - 1)
              setMinutes(59)
            }
          } else {
            setMinutes(mins - 1)
            setSeconds(59)
          }
        }
        if (hrs === 0 && mins <= 2) {
          setColorTimer('red')
        }
      }, 1000)
      return () => {
        clearInterval(sampleInterval)
      }
    }
  })

  const handleNext = (pro) => async (event) => {
    let validNext = false

    /* if (props.id.takePhoto && next > 0) {
      console.log('ENTROOOO1')
      await navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === 'videoinput') {
              if (device.label) {
                validNext = true
              } else {
                validNext = false
                validUserMedia('error')
              }
            }
          })
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`)
          validNext = false
          validUserMedia('error')
        })
    } else {
      validNext = true
    }*/

    // if (validNext) {
    if (next > 0) {
      setValues({
        ...values,
        offset: values.limit,
        limit: values.limit + 1,
        radioValue: null,
      })
      setDisabledBack(false)
    } else {
      UpdateStatusTest(0)
    }

    if (cameraInit && props.id.takePhoto) {
      insertSavePhoto(props.id)
    } else {
      setNext(next + 1)

      if (insertNext) {
        insertSave()
      }
      UpdateStatusTestTimer()
    }
    // }
  }

  const handleBack = (pro) => (event) => {
    setValues({
      ...values,
      offset: 0,
      limit: 1,
      radioValue: null,
    })

    setDisabledBack(true)
    setNext(1)

    /*if (insertNext) {
      console.log("insertar");
      insert();
    }
    UpdateStatusTestTimer();*/
  }

  const UpdateStatusTestTimer = async () => {
    var hora = hrs + ':' + mins + ':' + secs
    var parts = hora.split(':')
    var total = parseInt(parts[0]) * 60 + parseInt(parts[1])

    const data = {
      timer: parseFloat(timerSave) - parseFloat(total),
    }

    await axios
      .put(`${urlServices}tests-users/${props.id.id}`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          if (response3.data.status === 4) {
            setMensageTestSTOP(true)
            props.callBackRefresh()
          }
        },
        (error) => {}
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const UpdateStatusTest = async (n) => {
    const data = {
      status: n === 0 ? 2 : 3,
    }

    await axios
      .put(`${urlServices}tests-users/${props.id.id}`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          props.callBackRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const insertSave = () => {
    setDisabledNext(true)
    let n = 0

    axios
      .get(
        `${urlServices}tests-results/test/${question.testId}/question/${question.id}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response3) => {
        //console.log('insertSave', response3.data)
        if (response3.data.id === undefined) {
          testResult.forEach((items) => {
            const data = {
              rightAnswer: items.rightAnswer,
              selectedAnswer:
                items.selectedAnswer === '' ? false : items.selectedAnswer,
              totalAnswers: items.totalAnswers,
              answerId: items.answerId,
              questionId: items.questionId,
              jobId: items.jobId,
              testId: items.testId,
              totalScore: items.totalScore,
              totalQuestions: items.totalQuestions,
              userId: localStorage.getItem('id'),
            }
            insert(data)
            n++
          })

          if (n === testResult.length) {
            setTestResult([])
            setDisabledNext(false)
            setInsertNext(false)
          }
        } else {
          deleteSave()

          testResult.forEach((items) => {
            const data = {
              rightAnswer: items.rightAnswer,
              selectedAnswer:
                items.selectedAnswer === '' ? false : items.selectedAnswer,
              totalAnswers: items.totalAnswers,
              answerId: items.answerId,
              questionId: items.questionId,
              jobId: items.jobId,
              testId: items.testId,
              totalScore: items.totalScore,
              totalQuestions: items.totalQuestions,
              userId: localStorage.getItem('id'),
            }
            insert(data)
            n++
          })

          if (n === testResult.length) {
            setTestResult([])
            setDisabledNext(false)
            setInsertNext(false)
          }
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const insert = async (data) => {
    await axios
      .post(`${urlServices}tests-results`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response3) => {
          //console.log('entro')
          return true
        },
        (error) => {
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const deleteSave = async () => {
    await axios
      .delete(
        `${urlServices}tests-results/test/${question.testId}/question/${question.id}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response3) => {
        setQuestion('')
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const handleSaved = (pro) => (event) => {
    UpdateStatusTest(1)

    props.callBackRefresh()
    props.exit()
  }

  const handleExit = (pro) => (event) => {
    // setAudioQuestionStart(0)
    // setAudioQuestion(false)
    // setVideoStart(0)
    setVideoAuto(false)
    setMensageExit(true)
  }

  const handleContinue = (pro) => (event) => {
    setMensageExit(false)
  }

  /*const handleContinueUserMedia = (pro) => (event) => {
    photo || setCameraInit(true)
    setUserMediaError(false)
  }*/

  const handleCloseDialog = (pro) => (event) => {
    UpdateStatusTestTimer()
    props.exit()
  }

  const handleRadioChange = (event, question, answer) => {
    if (event.target.value !== undefined) {
      if (question.questiontypeId === 1) {
        let out = [],
          questionsRows = [...rows.question],
          totalAnswer = question.answer.length

        question.answer.forEach((items) => {
          if (items.id === answer.id) {
            out.push({
              rightAnswer: items.rightAnswer,
              selectedAnswer: true,
              totalAnswers: totalAnswer,
              answerId: answer.id,
              questionId: question.id,
              jobId: rows.testjob[0].id,
              testId: question.testId,
              totalScore: rows.totalScore,
              totalQuestions: rows.totalQuestions,
            })
          } else {
            out.push({
              rightAnswer: items.rightAnswer,
              selectedAnswer: false,
              totalAnswers: totalAnswer,
              answerId: items.id,
              questionId: question.id,
              jobId: rows.testjob[0].id,
              testId: question.testId,
              totalScore: rows.totalScore,
              totalQuestions: rows.totalQuestions,
            })
          }
        })

        setTestResult(out)

        questionsRows.forEach((items) => {
          if (question.id === items.id) {
            question.answer.forEach((items2) => {
              if (items2.id === answer.id) {
                items2.newvalue = true
              } else {
                items2.newvalue = false
              }
            })
          }
        })
      } else if (question.questiontypeId === 2) {
        let totalAnswer = question.answer.length,
          rsl = [...testResult]

        rows.question.forEach((items) => {
          if (items.id === question.id) {
            items.answer.forEach((items2) => {
              if (items2.id === answer.id) {
                items2.valueDefault = event.target.checked
              }
            })
          }
        })

        if (rsl.length === 0) {
          makeALoopWait()
        }

        async function makeALoopWait() {
          question.answer.forEach((items2) => {
            rsl.push({
              rightAnswer: items2.rightAnswer,
              selectedAnswer: '',
              totalAnswers: totalAnswer,
              answerId: items2.id,
              questionId: question.id,
              jobId: rows.testjob[0].id,
              testId: question.testId,
              totalScore: rows.totalScore,
              totalQuestions: rows.totalQuestions,
            })
          })

          setTestResult(rsl)
        }

        rsl.forEach((items3) => {
          if (items3.answerId === answer.id) {
            items3.selectedAnswer = event.target.checked
          }
        })

        setTestResult(rsl)
      }

      setQuestion(question)
      setInsertNext(true)
      setDisabledNext(false)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const capturePhoto = (img) => {
    setTestCamera(img ? false : true)
    setPhoto(img)
  }

  const insertSavePhoto = (testUser) => {
    const data = {
      picture: photo,
      testUserId: testUser.id,
    }

    axios
      .post(`${urlServices}tests-users-photos`, data, {
        headers: {
          application: keyAplication,
          Authorization: 'Bearer ' + keyAuthorization,
        },
      })
      .then(
        (response) => {
          setCameraInit(false)
          setTestCamera(false)
          return true
        },
        (error) => {
          console.log(error)
          setError('error')
          setMensaje(
            'Existen inconvenientes en el registro de la foto. Por favor, notifique al administrador.'
          )
          setOpenAlert(true)
          setCameraKey(Math.floor(Math.random() * 100))
          setTestCamera(true)
          console.log(error)
        }
      )
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.log(e)
        }
      })
  }

  const validUserMedia = (response) => {
    setCameraInit(false)
    setUserMediaError(response === 'error' || true)
  }

  /*const VisibilityOffClick = () => {
    if (!audioQuestion) {
      var au = document.createElement("audio");

      au.autoplay = true;
      au.src =
        "https://polygraph.web.aplios.software/media/AUDIOS/PREGUNTA_1.mp3";

      // Una vez cargados los metadatos, muestra la duración en la consola
      au.addEventListener(
        "loadedmetadata",
        function () {
          var duration = au.duration;
          // console.log("La duración de la canción es de:" + duration + " seconds");
          setAudioQuestionStart(duration);
        },
        false
      );
      setAudioQuestion(true);
    }
  };

  const HearingClick = () => {
    if (!videoAuto) {
      var au = document.createElement("video");

      au.autoplay = true;
      au.src = "https://polygraph.web.aplios.software/media/FONDO BLANCO/1.mp4";

      // Una vez cargados los metadatos, muestra la duración en la consola
      au.addEventListener(
        "loadedmetadata",
        function () {
          var duration = au.duration;
          //console.log(
           // "La duración de la canción es de:" + duration + " seconds"
         // );
          setVideoStart(duration);
        },
        false
      );
      setVideoAuto(true);
    }
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };*/

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      // onClose={handleCloseDialog(true)}
      keepMounted
      scroll="body"
      style={{ zIndex: 100 }}
      PaperProps={{
        classes: { root: classes.DialogStyle },
      }}
    >
      <Slide direction="down" in={openAlert} mountOnEnter unmountOnExit>
        <Alert
          onClose={handleClose}
          severity={error === 'error' ? 'error' : 'success'}
          elevation={6}
          variant="filled"
        >
          {mensaje}
        </Alert>
      </Slide>
      <DialogContent dividers className={classes.styleDialogContent}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <BorderColor className={classes.iconWhite} />{' '}
              <span style={{ textTransform: 'uppercase' }}>{rows.name}</span>
            </div>
          </CardHeader>
          <CardBody>
            {props.id.takePhoto && cameraInit ? (
              <Grid item xs={12} sm={12} style={{ padding: '15px 30px' }}>
                <Animated
                  animationIn="fadeIn"
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <Grid
                    container
                    spacing={1}
                    className={classes.containerProfile}
                    style={{
                      margin: '15px 0 15px 0',
                    }}
                  >
                    <Grid item xs={12} sm={12} md={7}>
                      <Fragment>
                        <div className={classes.fontParagraph}>
                          <b>Señor aspirante</b>
                          <br></br>
                          <br></br>
                          Antes de iniciar es necesario que <b>usted</b> tome
                          una foto con su dispositivo presionando el botón{' '}
                          <b>Tomar foto</b>. Además, se tomarán varias fotos de
                          forma aleatoria hasta que finalice la prueba.
                          <br></br>
                          <br></br>Una vez que tome la foto se activa el botón{' '}
                          <b>Iniciar</b> para rendir la prueba.
                        </div>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                      <center>
                        <Camera
                          key={cameraKey}
                          onCapture={capturePhoto}
                          userMedia={validUserMedia}
                        />
                      </center>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <br></br>
                      <center>
                        <Button
                          type="submit"
                          className={classes.buttonLeftSteps2}
                          onClick={handleCloseDialog(true)}
                        >
                          Salir
                        </Button>
                        <Button
                          type="submit"
                          className={classes.buttonSubmit}
                          onClick={handleNext()}
                          disabled={testCamera ? true : false}
                          endIcon={<ArrowRightAlt />}
                        >
                          Iniciar
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                </Animated>
              </Grid>
            ) : mensageTestSTOP ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Información</AlertTitle>
                      El administrador detuvo tu prueba por alguna razón, por
                      favor comunicarte con la persona encargada.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            ) : mensageExit ? (
              (<Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Información</AlertTitle>
                      Puede salir de la prueba sin ningún problema, las
                      preguntas realizadas será registrada para{' '}
                      <b>seguir en otro momento</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinue(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid> /*: userMediaError ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Advertencia</AlertTitle>
                      No puede continuar con la prueba, se requiere permisos
                      para acceder a la cámara. Cuando lo permita presione el
                      botón <b>Continuar la prueba</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinueUserMedia(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            )*/ /*: userMediaError ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Advertencia</AlertTitle>
                      No puede continuar con la prueba, se requiere permisos
                      para acceder a la cámara. Cuando lo permita presione el
                      botón <b>Continuar la prueba</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinueUserMedia(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            )*/ /*: userMediaError ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Advertencia</AlertTitle>
                      No puede continuar con la prueba, se requiere permisos
                      para acceder a la cámara. Cuando lo permita presione el
                      botón <b>Continuar la prueba</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinueUserMedia(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            )*/ /*: userMediaError ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ padding: '0 40px 20px 40px' }}
                >
                  <Animated
                    animationIn="bounceIn"
                    animationInDuration={300}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={exit}
                        alt="exit"
                        style={{
                          width: 170,
                        }}
                      />
                    </center>
                    <br></br>
                    <Alert severity="warning">
                      <AlertTitle>Advertencia</AlertTitle>
                      No puede continuar con la prueba, se requiere permisos
                      para acceder a la cámara. Cuando lo permita presione el
                      botón <b>Continuar la prueba</b>.
                    </Alert>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleContinueUserMedia(true)}
                      >
                        Continuar la prueba
                      </Button>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleCloseDialog(true)}
                      >
                        Salir
                      </Button>
                    </center>
                  </Animated>
                </Grid>
              </Grid>
            )*/)
            ) : (
              <Grid container>
                {next === 0 ? (
                  <Fragment>
                    <Grid item xs={12} sm={5}>
                      <center>
                        <img
                          src={test}
                          alt="test"
                          style={{ marginTop: 50, width: 300 }}
                        />
                      </center>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className={classes.fontParagraph}>
                        <br></br>
                        <br></br>
                        <b>Señor aspirante</b>
                        <br></br>
                        <br></br>La siguiente prueba técnica contiene preguntas
                        de selección única y múltiples, tome en cuenta que debe
                        responder todas las preguntas dentro del límite de
                        tiempo asignado para este cuestionario. Para dar inicio
                        presione el botón Comenzar.
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <br></br>
                      <center>
                        <Button
                          type="submit"
                          className={classes.buttonLeftSteps2}
                          onClick={handleCloseDialog(true)}
                        >
                          Salir
                        </Button>
                        <Button
                          type="submit"
                          className={classes.buttonSubmit}
                          onClick={handleNext()}
                          endIcon={
                            props.id.status === 2 ? (
                              <ArrowRightAlt />
                            ) : (
                              <WatchLater />
                            )
                          }
                        >
                          {props.id.status === 2 ? 'Continuar' : 'Comenzar'}
                        </Button>
                      </center>
                    </Grid>
                  </Fragment>
                ) : next <= totalPages ? (
                  <Fragment>
                    {/*props.id.takePhoto && (
                      <CameraAutoCapture
                        data={props.id}
                        tpmTest={false}
                        userMedia={validUserMedia}
                      />
                    )*/}
                    <Grid
                      item
                      xs={8}
                      sm={8}
                      style={{
                        padding: '1px 3px',
                        color: '#333',
                        fontWeight: 'bold',
                        fontSize: 13,
                      }}
                    >
                      <Person
                        style={{ color: '#333', verticalAlign: 'middle' }}
                      />{' '}
                      {user.name}&nbsp;({user.document})
                    </Grid>
                    {/*<Grid
                      item
                      xs={4}
                      sm={4}
                      style={{
                        padding: 3,
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <VisibilityOff
                          className={classes.iconThemeHover}
                          onClick={VisibilityOffClick}
                        />
                        &nbsp;&nbsp;
                        <Hearing
                          className={classes.iconThemeHover}
                          onClick={HearingClick}
                        />
                      </div>
                    </Grid>*/}
                    {videoAuto ? (
                      <Grid item xs={12} sm={12}>
                        <center>
                          <video
                            width="250px"
                            autoPlay
                            style={{ borderRadius: 40 }}
                          >
                            <source
                              src={'/media/FONDO BLANCO/1.mp4'}
                              type="video/mp4"
                            />
                          </video>
                        </center>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {rows.question
                      .slice(values.offset, values.limit)
                      .map((row, index) => {
                        return (
                          <Grid item xs={12} sm={12} style={{ padding: 30 }}>
                            <Animated
                              animationIn="fadeIn"
                              animationInDuration={1000}
                              isVisible={true}
                            >
                              <div className={classes.fontParagraph}>
                                &nbsp;&nbsp;&nbsp;
                                <b style={{ fontSize: 16 }}>
                                  {row.statement}
                                </b>{' '}
                                {/*<small>({row.questiontype.name})</small>*/}
                              </div>
                              <br></br>
                              {row.answer.map((row2x, n) => {
                                return (
                                  row2x.status === 1 && (
                                    <FormControl
                                      component="fieldset"
                                      style={{
                                        margin: '5px 0px 0px 35px',
                                        width: '100%',
                                      }}
                                    >
                                      {row.questiontypeId === 2 ? (
                                        <FormControlLabel
                                          control={
                                            <GreenCheckbox
                                              onClick={(event) => {
                                                handleRadioChange(
                                                  event,
                                                  row,
                                                  row2x
                                                )
                                              }}
                                              checked={
                                                row2x.valueDefault === undefined
                                                  ? false
                                                  : row2x.valueDefault
                                              }
                                              name={row2x.statement}
                                            />
                                          }
                                          label={row2x.statement}
                                          style={{ color: 'dimgray' }}
                                        />
                                      ) : row.questiontypeId === 1 ? (
                                        <FormControlLabel
                                          value={row2x.rightAnswer}
                                          control={<GreenRadio />}
                                          label={row2x.statement}
                                          style={{ color: 'dimgray' }}
                                          checked={
                                            row2x.newvalue !== undefined
                                              ? row2x.newvalue
                                              : false
                                          }
                                          onClick={(event) => {
                                            handleRadioChange(event, row, row2x)
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </FormControl>
                                  )
                                )
                              })}
                            </Animated>
                          </Grid>
                        )
                      })}

                    <Grid xs={12} md={12} style={{ marginTop: 30 }}>
                      <center>
                        {/*<Button
                        type="submit"
                        className={classes.buttonLeftSteps}
                        // onClick={cancelSteps}
                      >
                        Deshacer
                      </Button>*/}
                        {next !== totalPages ? (
                          <Fragment>
                            <Button
                              type="submit"
                              className={classes.buttonLeftSteps2}
                              onClick={handleBack()}
                              startIcon={<Replay />}
                              disabled={disabledBack}
                            >
                              Regresar al inicio
                            </Button>
                            <Button
                              type="submit"
                              className={classes.buttonSubmit}
                              onClick={handleNext()}
                              endIcon={<ArrowRightAlt />}
                              disabled={disabledNext}
                            >
                              Continuar
                            </Button>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Button
                              type="submit"
                              className={classes.buttonLeftSteps2}
                              onClick={handleBack()}
                              startIcon={<Replay />}
                              //disabled={disabledNext}
                            >
                              Regresar al inicio
                            </Button>
                            <Button
                              type="submit"
                              className={classes.buttonSubmit}
                              onClick={handleNext()}
                              endIcon={<Save />}
                              // disabled={disabledNext}
                            >
                              Finalizar
                            </Button>
                            {/*<Webcam
                              videoConstraints={videoConstraints}
                              width={0}
                              height={0}
                              screenshotFormat="image/jpeg"
                              audio={false}
                              ref={webcamRef}
                            />*/}
                          </Fragment>
                        )}
                      </center>
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid item xs={12} sm={12} style={{ padding: 20 }}>
                    <center>
                      <img
                        src={successImg}
                        alt="successPassword"
                        style={{
                          width: '150px',
                        }}
                      />
                      <br></br>
                      <Animated
                        animationIn="bounceIn"
                        animationInDuration={1000}
                        isVisible={true}
                      >
                        <div className={classes.titleCard}>
                          Usted ha finalizado el test de forma exitosa.
                        </div>
                      </Animated>
                    </center>

                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={handleSaved()}
                      >
                        Aceptar
                      </Button>
                    </center>
                  </Grid>
                )}
              </Grid>
            )}
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ paddingRight: 35, marginLeft: 15 }}
          >
            {next <= totalPages && next > 0 ? (
              <Fragment>
                {!mensageTestSTOP ? (
                  <>
                    <div style={{ float: 'left' }}>
                      <span className={classes.cardTitleDialog}>
                        <small style={{ color: colorTimer }}>
                          Pregunta &nbsp;
                          {next <= totalPages && next > 0
                            ? rows.totalQuestions - questionTotal + next
                            : ''}{' '}
                          &nbsp;de&nbsp;{rows.totalQuestions}{' '}
                        </small>
                      </span>
                      &nbsp; &nbsp;
                      <Button
                        type="submit"
                        className={classes.buttonLeftSteps2}
                        onClick={handleExit(true)}
                      >
                        Salir
                      </Button>
                    </div>

                    <div style={{ float: 'right' }}>
                      <span className={classes.cardTitleDialog}>
                        <WatchLater
                          fontSize="small"
                          className={classes.iconTheme}
                        />{' '}
                        <small style={{ color: colorTimer }}>
                          {(hrs < 10 ? '0' + hrs : hrs) +
                            ':' +
                            (mins < 10 ? '0' + mins : mins) +
                            ':' +
                            (secs < 10 ? '0' + secs : secs)}
                        </small>
                      </span>
                      {/*<CameraVideo />*/}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
